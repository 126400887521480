import '../styles/index.scss';
import '../styles/portfolio.scss';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import Preloader from './Components/Preloader'
// import Splitting from "splitting";
gsap.registerPlugin(SplitText);

// import Slideshow from './Slideshow';
import Cursors from "./Cursors";
import { preloadImages, fontOpen, fontPlayfair } from './utils/index';
import Navigation from './Components/Navigation';

// import Transition from './utils';

// Splitting();

// const bgColors: ['#27172e', '#1f1322', '#454d53', '#2d1f2d'];
class App {
  constructor() {
    this.createPreloader()
    this.createNavigation()
    this.createCursors()
  }

  createPreloader() {
    this.preloader = new Preloader
    console.log(this.preloader, 'Preloader')
    // this.preloader.on('completed', this.onPreloaded.bind(this))
  }

  createNavigation() {
    this.navigation = new Navigation
    console.log(this.navigation, 'Navigation')
  }
  
  createCursors() {
    Cursors.init()
  }

}

new App()



