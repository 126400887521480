import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { preloadImages, fontOpen, fontPlayfair } from '../utils/preload';
import Events from '../events/index'

export default class Preloader {
  constructor() {
    this.onLoad()
    // this.emit = new Events()
  }

  
onLoad() {
  preloadImages('img'), fontOpen, fontPlayfair.then(() => {
	  const masterTl = gsap.timeline();
    const el = document.querySelector('blockquote');
    const split = new SplitText(el, {
      type: 'lines, words, chars',
      linesClass: 'ts-line'
    });
  
    const loadedAnimationTl = gsap.timeline({
        delay: 0.5,
        onStart: () => {
          gsap.set(el, { autoAlpha: 1 });
        },
      })
      .to(".loading__text", {
        duration: 2,
        opacity: 0,
      })
      .addLabel('enter', '+=1')
      .from(split.chars, 
        {
          autoAlpha: 0,
          y: 50,
          ease: 'back(4)',
          stagger: {
            from: 'start',
            each: 0.05
          }
        }, 'enter')
      // .fromTo(split.chars, 
      //   {
      //     autoAlpha: 0
      //   },
      //   {
      //     autoAlpha: 1, 
      //     duration: 0.6,
      //     ease: 'Power1.out',
      //     stagger: 0.2
      //   }, 'enter')
      .addLabel('exit', '+=1')
      .to(split.words, 
        { 
          yPercent: -200,
          duration: 0.4,
          ease: 'circ.in',
          stagger: 0.1 
        }, 'exit')
      .to(split.words, 
        {
          autoAlpha: 0,
          duration: 0.4,
          ease: 'Power1.in',
          stagger: 0.1
        }, 'exit')
      .addLabel('final')
      .to(".bg__transition--slide", {
        duration: 1,
        scaleY: 0,
        transformOrigin: "top center",
        ease: "expo.out",
        onComplete: () => {
          // slider.initAnimation();
          gsap.set(".loading__wrapper", {
            pointerEvents: "none",
            autoAlpha: 0,
          });
        },
      }, 'final+=1.2');
  
    const pageAnimationTl = gsap
      .timeline({
        delay: loadedAnimationTl.duration(),
        onComplete: () => {
          // Events.emit('completed')
          // Cursors.init();
          // Cursors.initEventsOnSlider(slider);
          // Navigation.barba();
          // console.log(barba(), "is Barba here")
        },
      })
      // .from([".frame__logo", ".frame__button", ".frame__artist > span", ".frame__credits > span"], {
      // 	duration: 1,
      // 	opacity: 0,
      // 	yPercent: 100,
      // 	stagger: 0.1,
      // 	ease: "expo.out",
      // });
  
    masterTl.add(loadedAnimationTl, 0);
    masterTl.add(pageAnimationTl, pageAnimationTl.duration() - 0.5)
  });
}

}