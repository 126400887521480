import imagesLoaded from "imagesloaded";
import FontFaceObserver from 'fontfaceobserver';

export const preloadImages = (selector) => {
  return new Promise((resolve) => {
    imagesLoaded(document.querySelectorAll(selector), { background: true }, resolve);
  });
};

export const fontOpen = new Promise((resolve) => {
  new FontFaceObserver("Open Sans").load().then(() => {
    resolve();
  });
});

export const fontPlayfair = new Promise((resolve) => {
  new FontFaceObserver("Playfair Display").load().then(() => {
    resolve();
  });
});
