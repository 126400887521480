import barba from '@barba/core';
import gsap from 'gsap'
import Transition from '../utils/Transition'

export default class Navigation {
  constructor() {
    this.createTransition();
    this.barba();
  }

  createTransition() {
    this.transition = new Transition();
    console.log(this.transition, "Transition??");
  } 
  

  barba() {
    console.log(this.barba, 'WTF!!!!!!!')
    this.animationRunning = false;
    let that = this;
    barba.init({
      transitions: [{
        // name: 'from-home-transition',
        // name: 'transition',
        // from: {
        //   namespace: ["home"]
        // },
        leave(data) {
          // that.animationRunning = true;
          console.log(data, 'Leave')
          // that.asscroll.disable();
          this.transition(show)
          return gsap.timeline()
            .to(data.current.container, {
              opacity: 0,
              duration: 0.5
            })
        },
        enter(data) {
          console.log(data, 'Enter')
          // that.transistion.show({
          //   color: page.element.getAttribute('data-color')
          // })
          // that.asscroll = new ASScroll({
          //   disableRaf: true,
          //   containerElement: data.next.container.querySelector("[asscroll-container]")
          // })
          // that.asscroll.enable({
          //   newScrollElements: data.next.container.querySelector('.scroll-wrap')
          // })
          return gsap.timeline()
            .from(data.next.container, {
              opacity: 0,
              onComplete: () => {
                // that.container.style.visibility = 'hidden';
                // that.animationRunning = false;
              }
            })
        }    
      }],
      // {
      //   // name: 'from-inside-transition',
      //   name: 'transition',
      //   from: {
      //     namespace: ["about"]
      //   },
      //   leave(data) {
      //     // that.asscroll.disable();
      //     that.transition.show({
      //       color: page.element.getAttribute('data-color')
      //     });
      //     return gsap.timeline()
      //       // .to(this.transition.show({
      //       //   color: page.element.getAttribute('data-color')
      //       // }))
      //       .to(data.current.container, {
      //         opacity: 0
      //       })
      //   },
      //   enter(data) {
      //     // that.asscroll = new ASScroll({
      //     //   disableRaf: true,
      //     //   containerElement: data.next.container.querySelector("[asscroll-container]")
      //     // })
      //     // that.asscroll.enable({
      //     //   horizontalScroll: true,
      //     //   newScrollElements: data.next.container.querySelector('.scroll-wrap')
      //     // })

      //     // cleaning old arrays
      //     // that.imageStore.forEach(m => {
      //     //   that.scene.remove(m.mesh)
      //     // });
      //     // that.imageStore = [];
      //     // that.materials = [];
      //     // that.addObjects();
      //     // that.resize();
      //     // that.addClickEvents();
      //     that.container.style.visibility = 'visible';
      //     this.transition.hide();
      //     return gsap.timeline()
      //       // .to(this.transition.hide())
      //       .from(data.next.container, {
      //         opacity: 0
      //       })
        // }
      // }]
    });
  }
}
